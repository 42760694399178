import { lazy } from "react";

// project import
import Loadable from "@/components/Loadable";
import MinimalLayout from "@/layout/MinimalLayout";
import { Navigate } from "react-router-dom";
const Login = Loadable(lazy(() => import("@/pages/areaAberta/login/Login")));
const StatusServicos = Loadable(
  lazy(() => import("@/pages/areaAberta/status-servicos"))
);

const AreaAbertaRoutes = {
  path: "/",
  element: <MinimalLayout />,
  children: [
    {
      path: "/",
      element: <Navigate {...{ to: "/login" }} />,
    },

    {
      path: "login",
      element: <Login />,
    },

    {
      path: "status-servicos",
      element: <StatusServicos />,
    },

    {
      path: "*",
      element: <Navigate {...{ to: "/login" }} />,
    },
  ],
};

export default AreaAbertaRoutes;
